import React, { useEffect,useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableComponent from '../../../SharedModule/Table/Table';
import moment from 'moment';

import { viewConsent } from "../Store/Actions/ConsentsManagementActions";
import { data } from "jquery";

function ConsentsView(props) {
    const appHeadCells = [
        {
            id: 'appName', numeric: false, disablePadding: true, label: 'App Name', enableHyperLink: false, fontSize: 12, width: '15%'
        },
        {
            id: 'appDevName', numeric: false, disablePadding: false, label: 'App Dev Name', enableHyperLink: false, fontSize: 12, width: '15%'
        },
        {
            id: 'orgName', numeric: false, disablePadding: false, label: 'App Org Name', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'homePageUrl', numeric: false, disablePadding: false, label: 'App Homepage', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'consentDate', numeric: false, disablePadding: false, label: 'Consent Date', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'appTokenExpiry', numeric: false, disablePadding: false, label: 'App Token Expiry', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'patientDataScope', numeric: false, disablePadding: false, label: 'Patient Data Scope', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'revokeReqBody', numeric: false, disablePadding: false, label: 'Revoke Consent', enableAjax: true, enableHyperLink: false, fontSize: 12, width: '10%'
        }
    ];

    const dispatch = useDispatch();
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [consents, setConsents] = React.useState([]);
    const defaultVale = {
        "consentSearch": "",
        "appStatus": "SUBMITTED",
    }
    const [values, setValues] = React.useState(defaultVale);
    const userId = sessionStorage.getItem('isLoggedIn');
    const consentSearchFun = userId => dispatch(viewConsent(userId));
    const payload = useSelector((state) => state.consentsManagementState.consents);
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        if (consents.length === 0 && !fetched) {
            consentSearchFun(userId);
            setFetched(true);
        }
        if (payload && payload.data) {
            setConsents(payload.data);
        }
        else if (payload === "") {
            setConsents([]);
        }
    }, [payload]);

   


    const onSearch = name => event => {
        setValues({ ...values, [name]: event.target.value });
        let queryString = event.target.value.toLowerCase();
        if (queryString.length === 0) {
            return setConsents(payload.data);
        }
        if (consents.length > 0 && payload.data) {
            let filterData = payload.data.filter(item =>
                (item.appName && item.appName.toLowerCase().indexOf(queryString) === 0) ||
                (item.appStatus && item.appStatus.toLowerCase().indexOf(queryString) === 0) ||
                (item.devFirstName && item.devFirstName.toLowerCase().indexOf(queryString) === 0) ||
                (item.devLastName && item.devLastName.toLowerCase().indexOf(queryString) === 0) ||
                (item.homePageUrl && (item.homePageUrl.split("://")[1]).toLowerCase().indexOf(queryString) === 0) ||
                (item.consentDate && moment(item.consentDate).format('YYYY-MM-DD').toLowerCase().indexOf(queryString) === 0) ||
                (item.appTokenExpiry && moment(item.appTokenExpiry).format('YYYY-MM-DD').toLowerCase().indexOf(queryString) === 0) ||
                (item.clientId && item.clientId.toLowerCase().indexOf(queryString) === 0) ||
                (item.consentId && item.consentId.toLowerCase().indexOf(queryString) === 0) ||
                (item.memberId && item.memberId.toLowerCase().indexOf(queryString) === 0) ||
                (item.patientDataScope && item.patientDataScope.toLowerCase().indexOf(queryString) === 0) ||
                (item.orgName && item.orgName.toLowerCase().indexOf(queryString) === 0)
            )
            setConsents(filterData);
        }
    };

     const onTableRowClick = data => null

    const getTablegetTableData = (data) => {
        if (data && data.length) {
            let tData = JSON.stringify(data);
            tData = JSON.parse(tData);
            tData.map((each, index) => {
                each.index = index;
                each.submittedby = each.status?.toUpperCase() == "SUBMITTED" ? (each.devFirstName + ' ' + each.devLastName) : each.auditUserID;
                each.appDevName = each.devFirstName + ' ' + each.devLastName;
                each.revokeReqBody = {
                    'userId': each.userId || '',
                    'clientId': each.clientId || '',
                    'consentId': each.consentId || '',
                    'clientSecret': each.clientSecret || '',
                    'memberId': each.memberId || '',
                    'appName': each.appName || '',
                }
            });
            return tData;
        } else {
            return [];
        }
    }

    return (
        <div className="content-wrapper block-elm" >
            {spinnerLoader ? <Spinner /> : null}

            {/* <!-- main content start --> */}


            <div className="frm-app-registration mt-1">
                <div className="tabs-container">
                    <div className="tab-body mt-2 pb-3">
                        {/* <!-- section start --> */}
                        <div className="tabs-inner-container mx-3">
                            <div className="tab-header">
                                <h1 className="tab-heading float-left frm-label">Filter by Keyword</h1>
                                <div className="clearfix" />
                            </div>

                            <div className="tab-body-bordered p-3 mt-0 plr-none">
                                <div className="form-wrapper col-sm-12 px-0 my-1" id="frm-app-search">
                                    <div className="mui-custom-form col-sm-12 col-md-12 txt-search m-0">
                                        <label className="visuallyhidden" for="app-search">App Search</label>
                                        <TextField
                                            id="app-search"
                                            value={values.consentSearch}
                                            onInput={onSearch('consentSearch')}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            placeholder="Filter by keyword.."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><div className="icon-filter"></div></InputAdornment>,
                                                id: 'app-search'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- section end --> */}

                        {/* <!-- section start --> */}
                        <div className="tab-holder mx-3 mt-3">
                            <TableComponent onTableRowClick={onTableRowClick} headCells={appHeadCells} tableData={getTablegetTableData(consents)} defaultSortColumn="addedAuditTimeStamp" sortOrder="asc" />
                        </div>
                        {/* <!-- section end --> */}
                    </div>
                </div>
            </div>
            {/* <!-- main content end --> */}

        </div>
    )
}

const mapDispatchToProps = { viewConsent };

export default connect(null, mapDispatchToProps)(ConsentsView);